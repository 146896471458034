<template>
  <div class="Student">
    <page-info :page-title="studentTitle"/>
    <div class="studentPage">
      <div class="left">
        <div class="leftTop">
          <div class="studentReward">
            <div class="boxTitle">
              <span class="boxTitleText">学生参赛获奖情况({{ nowYear }}年)</span>
            </div>
            <div class="chartBox">
              <div ref="rewardEl" class="chartStyle1"></div>
            </div>
          </div>
          <div class="studentExcellent">
            <div class="boxTitle"><span class="boxTitleText">学生优作学科排行（{{ nowMonth }}月）</span></div>
            <div class="chartBox">
              <!--              学生优作学科排行-->
              <div ref="excellentEl" class="chartStyle1"></div>
            </div>
          </div>
        </div>
        <div class="leftBottom">
          <!--          考级比例图-->
          <div class="boxTitle"><span class="boxTitleText">考级比例图（{{ nowYear }}年）</span></div>
          <div class="chartBox" style="width: 100%">
            <div ref="kaojiEl" style="width: 100%; height: 100%"></div>
          </div>
        </div>
      </div>
      <div class="right">
        <!--        学生积分排行榜单-->
        <div class="boxTitle"><span class="boxTitleText">学生积分排行榜单（{{ nowMonth }}）</span></div>
        <div
            style="width: 100%; height: calc(100% - 45px); display: flex; align-items: center; justify-content: center">
          <div class="jifenContainer">
            <div class="top3">
              <div v-for="(i, k) in top3" :key="k">
                <div class="top3Card" :data="`index${k}`">
                  <div class="top3Name">{{ i.studentName }}</div>
                  <div class="top3Num">{{ i.integralNum }}分</div>
                </div>
              </div>
            </div>
            <div style="display: flex; flex-wrap: wrap; justify-content: center; margin-top: 20px">
              <div v-for="(i, k) in jifenList" :key="k">
                <div class="after3Card" :data="`index${k}`">
                  <div class="after3CardStudentName">{{ i.studentName }}</div>
                  <div class="after3CardNum">{{ i.integralNum }}分</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageInfo from "../components/PageInfo";
import moment from "moment";
import {onMounted, reactive, ref, toRefs, onUnmounted, nextTick} from "vue";
import service from "../utils/axios";
import * as echarts from 'echarts';
import {useRouter} from "vue-router";
import {changePage} from "../utils/getPageTime";

export default {
  name: "Student",
  components: {PageInfo},
  data() {
    return {
      studentTitle: require('../assets/images/student/student_tip.png'),
      nowYear: moment().format('yyyy'),
      // nowMonth: moment().format('M'),
        nowMonth: '近30日',
    }
  },
  setup() {
    let router = useRouter()
    let rewardEl = ref(null)
    let excellentEl = ref(null)
    let kaojiEl = ref(null)

    let state = reactive({
      top3: [],
      awardList: [],
      exeProcessList: [],
      kaojiList: [],
      jifenListy: [],
      jifenList: [],
      e1: null,
      e2: null,
      e3: null,
      timeout: null,
    })

    const drawReward = () => {
      let options = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#0289F1', '#4CE2D4', '#F85A46',],
        legend: {
          top: 0,
          left: 'center',
          textStyle: {
            color: '#FFF'
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '60%',
            data: state.awardList.map(v => {
              return {value: v.awardNum, name: v.awardName, ratio: v.awardRatio}
            }),
            label: {
              alignTo: 'edge',
              formatter: '{d}%',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              color: '#FFF'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
              }
            }
          }
        ]
      }
      state.e1 = echarts.init(rewardEl.value);
      state.e1.setOption(options)
    }

    const drawExcellent = () => {
      let options = {
        xAxis: {type: 'value', axisLabel: {color: '#FFF'}},
        tooltip: {
          trigger: 'item'
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 50,
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#FFF'
          },
          axisTick: {
            show: false
          },
        },
        dataset: {
          source: state.exeProcessList,
          dimensions: ['name', 'num'],
        },
        series: [
          {
            type: 'bar', barWidth: 15, barCategoryGap: 60, itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0.5,
                x2: 1,
                y2: 0.5,
                colorStops: [
                  {offset: 0, color: '#0F86EB'},
                  {offset: 0.5, color: '#1CD7E5'},
                  {offset: 1, color: '#23FFE2'},
                ],
                global: false,
              },
              borderRadius: [0, 20, 20, 0]
            },
            barMaxWidth: 18,
          },
        ]
      }
      state.e2 = echarts.init(excellentEl.value);
      state.e2.setOption(options)
    }

    const drawkaoji = () => {
      let options = {
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#FFF'
          },
          data: state.kaojiList.filter(v=>v.type === '报名人数').map(v=> v.city)
        },
        legend: {
          left: 'center',
          top: 0,
          textStyle: {
            color: '#FFF'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        grid: {
          top: 40,
          bottom: 20,
          left: 70,
          right: 70
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#FFF'
          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            name: '报名人数',
            type: 'bar',
            data: state.kaojiList.filter(v => v.type === '报名人数'),
            itemStyle: {
              color: {
                type: 'linear',
                x: 0.5,
                y: 0,
                x2: 0.5,
                y2: 1,
                colorStops: [
                  {offset: 0, color: '#5AD6C7'},
                  {offset: 1, color: '#0679DB'},
                ],
                global: false,
              }
            }
          },
          {
            name: '通过人数',
            type: 'bar',
            data: state.kaojiList.filter(v => v.type === '通过人数'),
            itemStyle: {
              color: {
                type: 'linear',
                x: 0.5,
                y: 0,
                x2: 0.5,
                y2: 1,
                colorStops: [
                  {offset: 0, color: '#F1B959'},
                  {offset: 1, color: '#E34D29'},
                ],
                global: false,
              }
            }
          }
        ]
      }
      state.e3 = echarts.init(kaojiEl.value);
      state.e3.setOption(options)
    }

    const getData = async () => {
      const data = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/studentSituation/awards')
      const data2 = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/studentSituation/pduction')
      const data3 = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/studentSituation/distinction')
      const data4 = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/studentSituation/integral')
      state.awardList = data;
      state.exeProcessList = data2;
      state.kaojiList = data3;
      state.jifenListy = data4;
      let a = JSON.parse(JSON.stringify(data4));
      state.top3 = a.splice(0, 3);

      state.jifenList = a

      drawReward();
      drawExcellent();
      drawkaoji();
      state.timeout = await changePage(router, '学生情况');
    }

    onMounted(async () => {
      await getData();
    })

    onUnmounted(() => {
      state.e1 && state.e1.clear();
      state.e2 && state.e2.clear();
      state.e3 && state.e3.clear();
      state.timeout && clearTimeout(state.timeout)
    })


    return {
      rewardEl,
      excellentEl,
      kaojiEl,
      ...toRefs(state),
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";

.Student {
  @include pageMix;
  display: flex;
  flex-direction: column;

  .studentPage {
    flex: 1;
    padding: 40px 50px 50px;
    display: flex;
    @media(max-width: 1280px) {
      padding: 27px 34px 34px;
    }
  }

  .left {
    flex: 11;
    @media(max-width: 1280px) {
      width: 721px;
    }
  }

  .right {
    flex: 9;
    margin-left: 30px;
    text-align: center;
    position: relative;
    @include backgroundMix;
    background-image: url("../assets/images/student/studentSubject.png");
    @media(max-width: 1280px) {
      margin-left: 16px;
    }

  }

  .leftTop {
    height: 40%;
    display: flex;
    @media(max-width: 1280px) {
      height: 250px;
    }
  }

  .chartBox {
    flex: 1;
    padding: 20px;
    @media(max-width: 1280px) {
      padding: 10px;
    }
  }

  .studentReward {
    flex: 1;
    margin-right: 12px;
    @include backgroundMix;
    background-image: url("../assets/images/student/student_box2.png");
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    @media(max-width: 1280px) {
      margin-right: 8px;
    }
  }

  .studentReward::after {
    content: "";
    width: 238px;
    height: 98px;
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    @include backgroundMix;
    background-image: url("../assets/images/student/student_circle.png");
    z-index: 1;
    @media(max-width: 1280px) {
      width: 158px;
      height: 65px;
      bottom: 21px;
    }
  }

  .studentExcellent {
    flex: 1;
    margin-left: 12px;
    @include backgroundMix;
    background-image: url("../assets/images/student/student_box2.png");
    display: flex;
    align-items: center;
    flex-direction: column;
    @media(max-width: 1280px) {
      margin-left: 8px;
    }
  }

  .leftBottom {
    margin-top: 30px;
    height: 60%;
    @include backgroundMix;
    background-image: url("../assets/images/student/student_box3.png");
    display: flex;
    align-items: center;
    flex-direction: column;
    @media(max-width: 1280px) {
      margin-top: 27px;
      height: 316px;
    }
  }

  .left {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .leftBox {
    height: calc(100% / 3);
  }

  .leftBox + .leftBox {
    margin-top: 17px;
  }

  .boxTitle {
    display: inline-block;
    width: 335px;
    height: 45px;
    border-radius: 6px;
    position: relative;
    @media(max-width: 1280px) {
      width: 227px;
      height: 29px;
    }
  }

  .boxTitleText {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 45px;
    z-index: 10;
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: center;
    @media(max-width: 1280px) {
      font-size: 13px;
      line-height: 29px;
    }
  }

  .boxTitle::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(360deg, #0066CB 0%, rgba(0, 128, 255, 0) 100%);
    opacity: 0.6;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 6px;
  }

  .top3 {
    width: 100%;
    height: 289px;
    @include backgroundMix;
    background-image: url("../assets/images/student/student_top3.png");
    position: relative;
    @media(max-width: 1280px) {
      height: 193px;
    }
  }

  .top3Card {
    position: absolute;
    text-align: center;
  }

  .top3Card[data="index1"] {
    left: 57px;
    bottom: 27px;
    @media(max-width: 1280px) {
      left: 38px;
      bottom: 18px;
    }
  }

  .top3Card[data="index0"] {
    left: 251px;
    bottom: 44px;
    @media(max-width: 1280px) {
      left: 170px;
      bottom: 29px;
    }
  }

  .top3Card[data="index2"] {
    right: 50px;
    bottom: 12px;
    @media(max-width: 1280px) {
      right: 30px;
      bottom: 8px;
    }
  }

  .top3Name {
    font-family: Source Han Sans CN;
    text-align: center;
    color: #FFF;
    font-size: 22px;
    @media(max-width: 1280px) {
      font-size: 15px;
    }
  }

  .top3Num {
    margin-top: 2px;
    display: inline-block;
    width: 106px;
    height: 32px;
    background: #057BD6;
    border-radius: 16px;
    text-align: center;
    line-height: 32px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
    @media(max-width: 1280px) {
      font-size: 13px;
      width: 71px;
      height: 21px;
      line-height: 21px;
      border-radius: 11px;
    }
  }

  .after3Card {
    width: 171px;
    height: 204px;
    @include backgroundMix;
    position: relative;
    margin: 10px;
    @media(max-width: 1280px) {
      width: 114px;
      height: 135px;
      margin: 7px;
    }
  }

  .after3Card[data='index0'] {
    background-image: url("../assets/images/student/stu_4@2x.png");
  }

  .after3Card[data='index1'] {
    background-image: url("../assets/images/student/stu_5@2x.png");
  }

  .after3Card[data='index2'] {
    background-image: url("../assets/images/student/stu_6@2x.png");
  }

  .after3Card[data='index3'] {
    background-image: url("../assets/images/student/stu_7@2x.png");
  }

  .after3Card[data='index4'] {
    background-image: url("../assets/images/student/stu_8@2x.png");
  }

  .after3Card[data='index5'] {
    background-image: url("../assets/images/student/stu_9@2x.png");
  }


  .after3CardStudentName {
    font-family: Source Han Sans CN;
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 50px;
    color: #FFF;
    font-size: 22px;
    @media(max-width: 1280px) {
      bottom: 35px;
      font-size: 15px;
    }
  }

  .after3CardNum {
    position: absolute;
    bottom: 10px;
    width: 106px;
    left: 50%;
    transform: translateX(-50%);
    background: #057BD6;
    border-radius: 16px;
    text-align: center;
    padding: 3px 0;
    font-size: 20px;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
    @media(max-width: 1280px) {
      bottom: 8px;
      width: 71px;
      font-size: 13px;
      border-radius: 11px;
    }
  }

  .jifenContainer {
    width: 616px;
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (max-width: 1280px) {
      width: 410px;
    }
  }

  .chartStyle1 {
    width: 450px;
    height: 100%;
    z-index: 2;
    @media (max-width: 1280px) {
      width: 330px;
    }
  }
}
</style>
